/* homeStyles.css */
html, body {
    margin: 0;
    padding: 0;
    height: 100%; /* Ensure the body takes up the full height of the viewport */
  }
  
  .container {
    display: flex;
    justify-content: center; /* Align cards to the top of the container */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* 75% of viewport height */
    padding: 20px; /* Optional: Add padding for spacing */
    overflow-y: auto; /* Enable vertical scrolling */
    background-image: url('./images/IMG_0165 3.jpg'); /* Path to your image */
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 150vh; /* Space below the first card */
  }
  
  .card {
    width: 80%; /* Adjust the width as needed */
    max-width: 75%; /* Optional: Set a maximum width to prevent it from becoming too wide */
    height: 70%; /* Auto height to fit content */
    overflow-y: auto; /* Enable vertical scrolling */
  }

  .home-card {
    width: 50%; /* Set the width to 50% */
    max-width: 50%; /* Optional: Set a maximum width to prevent it from becoming too wide */
    margin-left: -45%; /* Align 25% from the left side */
    height: 75%; /* Auto height to fit content */
    overflow-y: auto; /* Enable vertical scrolling */
  }
  
  .card-content {
    overflow-y: auto;
    max-height: calc(100% - 50px); /* Adjust height as needed */
    overflow-y: auto; /* Enable vertical scrolling */
    flex: 1; /* Take up remaining space in the card */
  }

  .technology-grid-item {
    flex: 1 1 100%; /* Default to full width */
}


@media (min-width: 600px) {
    .technology-grid-item {
        flex: 1 1 40%; /* 2 columns on phones */
    }
}

@media (min-width: 900px) {
    .technology-grid-item {
        flex: 1 1 30%; /* 3 columns on tablets */
    }
}

@media (min-width: 1200px) {
    .technology-grid-item {
        flex: 1 1 25%; /* 4 columns on small desktops */
    }
}

@media (min-width: 1500px) {
    .technology-grid-item {
        flex: 1 1 14.28%; /* 7 columns on larger desktops */
    }
}

.description {
    white-space: pre-line;
}

.image-container {
  text-align: center;
  margin-top: 20px; /* Space above the image */
}

.responsive-image {
  width: 30%; /* 30% of the card's width */
  max-width: 150px; /* Set a max-width to prevent it from becoming too large */
  height: 0; /* Set height to 0 */
  padding-top: 30%; /* Maintain aspect ratio by using padding */
  border-radius: 50%; /* Circular crop */
  object-fit: cover; /* Ensure image covers the circular area */
  display: block; /* Center align the image */
  margin: 0 auto; /* Center align the image */
  position: relative; /* Ensure image can be positioned properly */
}

.responsive-image img {
  position: absolute; /* Position the image inside the container */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure image covers the container properly */
}

/* Media Query for smaller screens */
@media (max-width: 600px) {
  .responsive-image {
    width: 100px; /* Smaller size for small screens */
    height: 100px; /* Maintain height to ensure circular shape */
    padding-top: 100px; /* Maintain aspect ratio for smaller screens */
  }
}
