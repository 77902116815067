/* Reset styles */
.nowPlayingCard {
    flex-shrink: 0;
    border-radius: 6px;
    box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
    align-items: center;
    width: 300px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.95); /* Match the transparency */
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 1000; /* Ensure it stays above other content */
}

/* Removed hover effects */
.nowPlayingCard:hover {
    /* No hover effect */
}

.nowPlayingImage img {
    border-radius: 8px;
    border: none; /* Removed the black border */
    box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
    transition: all 0.5s ease;
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    margin: 10px;
}

/* Removed hover effects */
.nowPlayingImage img:hover {
    /* No hover effect */
}

#nowPlayingDetails {
    justify-content: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 54%;
    height: 100%;
}

.nowPlayingTitle, .playlistName {
    flex-shrink: 0;
    color: #000;
    white-space: nowrap;
    text-align: left;
    font-size: 20px;
    width: 100%;
}

.nowPlayingArtist, .playlistHeader {
    text-align: left;
    flex-shrink: 0;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}

.nowPlayingTime {
    text-align: left;
}

.nowPlayingState {
    text-align: center;
    width: 20%;
    padding: 10px;
}

.nowPlayingState img {
    filter: invert(100%);
    width: 100%;
}
